import React from "react"
import Layout from "../components/layout"

export default function projects() {
  return (
    <Layout>
      <p> Sorry. Nothing here yet. </p>
    </Layout>
  )
}
